import React, { useEffect, useState } from 'react';
import { QuestionMarkCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { Tooltip, Row, Col, Popconfirm, message, Form, Input, Table, Button, Typography } from 'antd';
import * as ApiHelper from '../Helpers/ApiHelpers';
import DashboardLayout from '../Layouts/DashboardLayout';
import SectionHeader from '../SectionHeader';
import RoleSelect from '../RoleSelect';
import moment from 'moment';
import _ from 'underscore';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

// Users
function Index(props) {
  const [activeUsers, setActiveUsers] = useState(props.activeUsers);
  const [otherUsers, setOtherUsers] = useState(props.otherUsers || []);

  initPostHog();
  posthog.setPersonPropertiesForFlags({'email': props.commonData.currentUserEmail})

  useEffect(() => {
    ApiHelper.prepHttpCall();
    locale.locale = props.commonData.locale
    update();

  }, [props.commonData.locale, props.commonData.currentUserEmail]);

  const update = () => {
    let url = '/api/v1/accounts/' + props.commonData.currentAccountUniqueToken + '/users';

    axios.get(url, ApiHelper.headers()).then(function (response) {
      setActiveUsers(response.data.activeUsers);
      setOtherUsers(response.data.pendingUsers);
    });
  };

  const onRoleChange = (id, e) => {

    let url = '/api/v1/accounts/' + props.commonData.currentAccountUid + '/users/' + id + '/';

    axios.patch(url, { role: e }, ApiHelper.headers()).then(function (response) {
      popMessage('Role updated');
    });
  };

  const [form] = Form.useForm();

  const popMessage = (str) => {
    message.info(str);
  };

  const popError = (str) => {
    message.error(str);
  };

  const cancelInvitationClick = (event, e) => {
    event.preventDefault();

    ApiHelper.prepHttpCall();

    let url = '/users/invitation/revoke/' + e.id;
    axios
      .get(url)
      .then(function (response) {
        let newArray = [...otherUsers];
        newArray = _.without(otherUsers, _.findWhere(otherUsers, { id: response.data.user.id }));

        // setPendingInvitations(newArray);

        // newArray = [...otherUsers];
        // newArray.remove(response.data.user);

        setOtherUsers(newArray);

        // setRevokedUsers(newArray);

        popMessage('User revoked');
      })
      .catch(function (error) {
        console.log('Error', error);
      });

    return;
  };

  const revokeInvitationLink = (event, e) => {
    event.preventDefault();

    axios
      .get('/users/invitation/revoke/' + e.id)
      .then(function (response) {
        let newArray = [...otherUsers];
        newArray.push(response.data.user);

        // setRevokedUsers(newArray);
        setOtherUsers(newArray);

        popMessage('User revoked');
      })
      .catch(function (error) {
        console.log('Error', error);
      });

    return;
  };

  const revokeUserLink = (event, e) => {
    event.preventDefault();

    axios
      .get('/users/invitation/revoke/' + e.id)
      .then(function (response) {
        let newArray = [...activeUsers];
        console.log('users back', response.data.user)
        newArray.pop(response.data.user);

        setActiveUsers(newArray);

        popMessage('User revoked');
      })
      .catch(function (error) {
        console.log('Error', error);
      });

    return;
  };

  const resendInvitationClick = (event, e, moveItem = true) => {
    event.preventDefault();

    let url = '/users/invitation/resend/' + e.id;

    axios
      .get(url)
      .then(function (response) {
        if (moveItem) {
          let newArray = _.without(
            otherUsers,
            _.findWhere(otherUsers, { id: response.data.user.id })
          );

          setRevokedUsers(newArray);

          newArray = [...otherUsers];
          newArray.push(response.data.user);

          setOtherUsers(newArray);
          // setPendingInvitations(newArray);
        }
        popMessage('Invitation resent');
      })
      .catch(function (error) {
        console.log('Error', error);
      });

    return;
  };

  const otherUsersTable = () => {
    let cols = [
      {
        title: 'Sent on',
        width: 150,
        dataIndex: '',
        className: 'subtle-table-col',
        key: '',
        render: (e) => friendlyDate(e.invitation_created_at)
      },
      {
        title: 'Email',
        width: 250,
        dataIndex: '',
        key: '',
        render: (e) => e.email
      },
      {
        title: ' ',
        width: 125,
        dataIndex: '',
        key: '',
        render: (e) => resendInvitationLink(e)
      },
      {
        title: ' ',
        width: 125,
        dataIndex: '',
        key: '',
        render: (e) => cancelInvitationLink(e)
      }
    ];
    return (
      <Table
        bordered
        pagination={false}
        columns={cols}
        dataSource={otherUsers}
        rowKey={(record) => record.id}
      />
    );
  };

  const friendlyDate = (d) => {
    if (d == undefined) {
      return '';
    } else {
      return moment(d.split('T')[0], 'YYYY-MM-DD').format('MMM Do, YYYY');
    }
  };

  const revokeLink = (e) => {
    if (props.commonData.isAccountOwner && e.id != props.commonData.currentUserId) {
      return (
        <Popconfirm
          title="Are you sure you want to revoke this user?"
          onConfirm={(event) => revokeUserLink(event, e)}>
          <Button type="primary" danger>
            Revoke
          </Button>
        </Popconfirm>
      );
    } else {
      return '';
    }
  };

  const resendInvitationLink = (e) => {
    if (props.commonData.isAccountOwner) {
      return (
        <a href="#" onClick={(event) => resendInvitationClick(event, e, false)}>
          Resend
        </a>
      );
    } else {
      return '';
    }
  };

  const cancelInvitationLink = (e) => {
    if (props.commonData.isAccountOwner) {
      return (
        <a href="#" onClick={(event) => cancelInvitationClick(event, e)}>
          Cancel
        </a>
      );
    } else {
      return '';
    }
  };

  const usersTable = () => {

    let tip = <>
        <div>Individual Contributor: Can only see/manage their own data</div>
        <br />
        <div>Team Observer: Has Individual Contributor permissions, and can view team analytics</div>
        <br />
        <div>Collaborator: Has Team Observer permissions, and can engage on the behalf of all users</div>
      </>

    let tooltip =
              <Tooltip title={ tip } >
                <QuestionMarkCircleIcon width="20px" style={{ marginLeft: '8px', color: 'gray', verticalAlign: 'middle' }} />
              </Tooltip>

    let cols = [
      {
        title: 'Email',
        dataIndex: '',
        key: '',
        render: (e) => {
          return (
            <>
              <div class="flex items-center">
                <div class="h-10 w-10 flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" src={e.headshot_url} alt="" />
                </div>
                <div class="ml-4">
                  <div class="font-medium text-gray-700">{e.full_name}</div>
                  <div class="text-gray-500">{e.email}</div>
                </div>
              </div>
            </>
          );
        }
      },
      {
        title: ' ',
        width: 125,
        dataIndex: '',
        key: '',
        render: (e) => revokeLink(e)
      },
      {
        title: <>Role { tooltip }</>,
        width: 350,
        dataIndex: '',
        key: '',
        render: (e) => {
          return (
            <div className='w-full'>
              <RoleSelect isMasterAdmin={ props.commonData.isMasterAdmin } onChange={ (val) => onRoleChange(e.id, val) } defaultValue={ e.role } />
            </div>
          );
        }
      }
    ];

    return (
      <Table
        bordered
        pagination={false}
        columns={cols}
        dataSource={activeUsers}
        rowKey={(record) => record.id}
      />
    );
  };

  const handleSubmit = (event) => {
    let user = {
      email: event.email
    };

    let params = { user: user };

    let url = '/api/v1/users/invitation';

    axios
      .post(url, params, ApiHelper.headers())
      .then(function (response) {
        console.log('response', response);
        let newArray = [...otherUsers];
        newArray.push(response.data.user);

        setOtherUsers(newArray);

        form.resetFields();

        popMessage('User invited');
      })
      .catch(function (error) {
        console.log('err', error);
        popError(error.response.data.message);
        return;
      });
  };

  return (
    <>
      <SectionHeader
        title={ locale.t("users.invite_your_team_title") }
        subtitle={ locale.t("users.invite_your_team_subtitle") } />

      <Row className="mb-6" gutter={16}>

        <Col xs={{ span: 24, order: 2 }}>

          <div class="mt-1 sm:col-span-2 sm:mt-0">
            <Form form={form} name="invite-user" onFinish={handleSubmit}>
              <Form.Item
                className="inline-block"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: locale.t("users.invalid_email_msg")
                  },
                  {
                    required: true,
                    message: locale.t("users.please_input_email_msg")
                  }
                ]}>
                <Input size="large" prefix={<UserIcon width={18} />} placeholder={ locale.t("users.email_placeholder") } />
              </Form.Item>

              <Form.Item className="inline-block pl-0 sm:pl-4">
                <Button type="primary" size="large" className="" htmlType="submit">
                  { locale.t("users.send_invitation_btn") }
                </Button>
              </Form.Item>
            </Form>
          </div>

          <SectionHeader title={ locale.t("users.active_users_title") }></SectionHeader>
          {usersTable()}

          <SectionHeader title={ locale.t("users.pending_invitations_title") }></SectionHeader>
          {otherUsersTable()}
        </Col>
      </Row>

      <div className='mt-16'>&nbsp;</div>

    </>
  );
}

export default Index;
