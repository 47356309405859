import React from 'react';
import { Slider } from 'antd';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon, CalendarIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import { I18n } from 'i18n-js';
import translations from '/app/frontend/translations.json';

const locale = new I18n(translations);

class Filters extends React.Component {
  render() {
    const { selectedListValue, sortBy, datePublishedDays, lists } = this.props;

    const getSortLabel = () => {
      switch (sortBy) {
        case 'newest':
          return locale.t('my_lists.action_bar.newest');
        case 'oldest':
          return locale.t('my_lists.action_bar.oldest');
        case 'most_engagements':
          return locale.t('my_lists.action_bar.most_engagements');
        case 'least_engagements':
          return locale.t('my_lists.action_bar.least_engagements');
        default:
          return locale.t('my_lists.action_bar.sort_by_label');
      }
    };

    return (
			<div className="flex gap-1 flex-wrap justify-between w-full">
				{!this.props.hideListSelect && (
					<Popover className="relative">
						{({ close }) => (
							<>
								<Popover.Button className="flex border-none items-center gap-1 rounded-full bg-gray-100 px-1 py-2 text-gray-700 text-xs">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16px"
										height="16px"
										fill="currentColor"
										viewBox="0 0 256 256"
									>
										<path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
									</svg>
									<span>{lists.find(list => list.value === selectedListValue)?.label}</span>
									<ChevronDownIcon className="w-4 h-4 text-gray-400" />
								</Popover.Button>
								<Popover.Panel className="text-sm absolute max-h-48 z-10 mt-2 bg-gray-100 p-2 border border-gray-200 rounded-md shadow-lg min-w-[300px] overflow-y-auto">
									<div>
										{lists.map((list) => (
											<div
												key={list.value}
												className="px-4 py-2 text-gray-700 hover:bg-white rounded-md cursor-pointer whitespace-nowrap"
												onClick={() => {
													this.props.onListChange(list.value);
													close(); // Close the popover after selection
												}}
											>
												{list.label}
											</div>
										))}
									</div>
								</Popover.Panel>
							</>
						)}
					</Popover>
				)}

				<Popover className="relative">
					{({ close }) => (
						<>
							<Popover.Button className="flex border-none items-center gap-1 rounded-full bg-gray-100 px-2 py-2 text-gray-700 text-xs">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16px"
									height="16px"
									fill="currentColor"
									viewBox="0 0 256 256"
								>
									<path d="M128,128a8,8,0,0,1-8,8H48a8,8,0,0,1,0-16h72A8,8,0,0,1,128,128ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Zm125.66-21.66a8,8,0,0,0-11.32,0L192,188.69V112a8,8,0,0,0-16,0v76.69l-26.34-26.35a8,8,0,0,0-11.32,11.32l40,40a8,8,0,0,0,11.32,0l40-40A8,8,0,0,0,229.66,162.34Z"></path>
								</svg>
								<span>{getSortLabel()}</span>
								<ChevronDownIcon className="w-4 h-4 text-gray-400" />
							</Popover.Button>
							<Popover.Panel className="absolute text-sm z-10 mt-2 border bg-gray-100 border-gray-200 p-2 rounded-md shadow-lg min-w-[300px]">
								<div>
									<div
										className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer whitespace-nowrap rounded-md"
										onClick={() => {
											this.props.onSortByChange('newest');
											close(); // Close the popover after selection
										}}
									>
										{locale.t('my_lists.action_bar.newest')}
									</div>
									<div
										className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer whitespace-nowrap rounded-md"
										onClick={() => {
											this.props.onSortByChange('oldest');
											close(); // Close the popover after selection
										}}
									>
										{locale.t('my_lists.action_bar.oldest')}
									</div>
									<div
										className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer whitespace-nowrap rounded-md"
										onClick={() => {
											this.props.onSortByChange('most_engagements');
											close(); // Close the popover after selection
										}}
									>
										{locale.t('my_lists.action_bar.most_engagements')}
									</div>
									<div
										className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer whitespace-nowrap rounded-md"
										onClick={() => {
											this.props.onSortByChange('least_engagements');
											close(); // Close the popover after selection
										}}
									>
										{locale.t('my_lists.action_bar.least_engagements')}
									</div>
								</div>
							</Popover.Panel>
						</>
					)}
				</Popover>

				<Popover className="relative">
					{({ close }) => (
						<>
							<Popover.Button className="flex border-none items-center gap-1 rounded-full bg-gray-100 px-2 py-2 text-gray-700 text-xs">
								<AdjustmentsHorizontalIcon className="w-4 h-4" />
								<span>
									{`${locale.t("my_lists.action_bar.max_label")} ${this.props.maxPostsPerUser} ${locale.t("my_lists.action_bar.posts_per_user_label")}`}
								</span>
								<ChevronDownIcon className="w-4 h-4 text-gray-400" />
							</Popover.Button>
							<Popover.Panel className="absolute text-sm z-10 mt-2 border bg-gray-100 border-gray-200 p-2 rounded-md shadow-lg min-w-[300px]">
								<Slider
									className="w-48"
									defaultValue={this.props.maxPostsPerUser}
									min={1}
									max={3}
									onAfterChange={(value) => {
										this.props.onMaxPostsPerUserChange(value);
										close(); // Close the popover after selection
									}}
								/>
							</Popover.Panel>
						</>
					)}
				</Popover>

				<Popover className="relative">
					{({ close }) => (
						<>
							<Popover.Button className="flex border-none items-center gap-1 rounded-full bg-gray-100 px-2 py-2 text-gray-700 text-xs">
								<CalendarIcon className="w-4 h-4" />
								<span>
									{`${locale.t("my_lists.action_bar.published_within_label")} ${this.props.datePublishedDays} ${locale.t("my_lists.action_bar.published_timeframe_label")}`}
								</span>
								<ChevronDownIcon className="w-4 h-4 text-gray-400" />
							</Popover.Button>
							<Popover.Panel className="absolute text-sm z-10 mt-2 border bg-gray-100 border-gray-200 p-2 rounded-md shadow-lg min-w-[300px]">
								<Slider
									className="w-48"
									defaultValue={this.props.datePublishedDays}
									min={1}
									max={14}
									onAfterChange={(value) => {
										this.props.onPublishedDateChange(value);
										close(); // Close the popover after selection
									}}
								/>
							</Popover.Panel>
						</>
					)}
				</Popover>
			</div>

    );
  }
}

export default Filters;

