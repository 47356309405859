
import React from 'react';
import { Popover, Tooltip, Button, Checkbox } from 'antd';
import Card from './Card';
import LinkButton from './LinkButton';
import PagesDropdown from './Engage/PagesDropdown';
import * as Helper from './Helpers/Helpers';
import Editor from './Editor';
import * as ApiHelper from './Helpers/ApiHelpers';
import FlyoutButton from './FlyoutButton';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  ForwardIcon,
  HandThumbUpIcon, HeartIcon, LightBulbIcon, LifebuoyIcon, FaceSmileIcon, CakeIcon
} from '@heroicons/react/24/outline';
const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

class EngageBlock extends React.Component {

  constructor(props) {
    super(props);

    ApiHelper.prepHttpCall();
    locale.locale = props.commonData.locale

    this.onEditorChange = this.onEditorChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.hoverContent = this.hoverContent.bind(this);
    this.reply = this.reply.bind(this);
    this.state = {
      comment: '',
      visible: true,
      selectedPage: '',
      hasReacted: false,
      reaction: locale.t("engage_block.like"),
      icon: <HandThumbUpIcon width='24px' />,
      loading: false,
      loadingText: 'Generating comment',
      saveAsDraft: false,
      isRepliesPage: props.isRepliesPage || false
    };
  }

  handleCheckboxChange(e) {
    this.setState({ saveAsDraft: e.target.checked });
  }

  async rewrite(promptId, content) {
    this.setState({ loading: true, comment: '' },
      () => {
        this.loadingInterval = setInterval(() => {
          this.setState((prevState) => ({
            loadingText: prevState.loadingText.endsWith('...')
              ? 'Generating comment'
              : prevState.loadingText + '.'
          }));
        }, 500);
      });

      const apiUrl = '/api/v1/ai_generator';

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt_id: promptId,
            content: content
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const msg = await response.json();
        this.setState({ comment: msg.content });
      } catch (err) {
        this.setState({ comment: 'Error fetching comment' });
        console.error(err);
      } finally {
        clearInterval(this.loadingInterval);
        this.setState({ loading: false, loadingText: 'Generating comment' });
      }
  }

	removeHTMLAndReplaceP(content) {
		content = content.replace(/style="[^"]*"/ig, '');
		content = content.replace(/<(img|style)\b[^>]*>/ig, '');
		content = content.replace(/<\/?(span|a)[^>]*>/ig, '');

		content = content.replace(/<\/(p|h[1-6]|blockquote|em)[^>]*>/ig, '\n');

		content = content.replace(/<(p|h[1-6]|blockquote|em)[^>]*>/ig, '');
		content = content.replace(/<br[^>]*>/ig, '');

		return content.trim();
	}

  onEditorChange(e) {
    this.setState({ comment: e });
    return;
  }

  reply() {
    const comment = this.removeHTMLAndReplaceP(this.state.comment);

    let params = {
      linkedInUserId: this.props.commonData.actAsLinkedInUserId,
      activityId: this.props.activity.id,
      comment: comment,
      pageId: this.state.selectedPage,
      saveAsDraft: this.state.saveAsDraft
    };

    let url = '/api/v1/engage/reply';

    let self = this;
    axios.put(url, params, ApiHelper.headers()).then(function (response) {
      self.props.onComment();
    });

    this.setState({ visible: false });

    setTimeout(() => {
      self.props.callParent();
    }, 200); 

    this.like(locale.t("engage_block.like"));

    return;
  }

  icon(reaction) {
    switch(reaction) {
      case locale.t("engage_block.like"):
        return <HandThumbUpIcon width='24px' />
      case locale.t("engage_block.support"):
        return <LifebuoyIcon width='24px' />
      case locale.t("engage_block.love"):
        return <HeartIcon width='24px' />
      case locale.t("engage_block.celebrate"):
        return <CakeIcon width='24px' />
      case locale.t("engage_block.insightful"):
        return <LightBulbIcon width='24px' />
      case locale.t("engage_block.funny"):
        return <FaceSmileIcon width='24px' />
    }
  }

  reactionMap(reaction) {
    switch(reaction) {
      case locale.t("engage_block.like"):
        return 'LIKE'
      case locale.t("engage_block.support"):
        return 'APPRECIATION'
      case locale.t("engage_block.love"):
        return 'EMPATHY'
      case locale.t("engage_block.celebrate"):
        return 'PRAISE'
      case locale.t("engage_block.insightful"):
        return 'INTEREST'
      case locale.t("engage_block.funny"):
        return 'ENTERTAINMENT'
    }
  }

  like(reaction) {
    let params = {
      linkedInUserId: this.props.commonData.actAsLinkedInUserId,
      activityId: this.props.activity.id,
      pageId: this.state.selectedPage,
      reaction: this.reactionMap(reaction)
    };

    this.setState({ hasReacted: true, reaction: reaction, icon: this.icon(reaction) });

    let self = this;

    let url = '/api/v1/engage/like-and-skip';

    axios.put(url, params, ApiHelper.headers()).then(function (response) {
      Helper.startPolling(
        self.props.commonData,
        response.data.jobId,
        self.props.activity.id,
        self.callback
      );
    });

    return false;
  }

  likeAndSkip() {
    this.like(locale.t("engage_block.like"));
    this.setState({ visible: false });
    let self = this;
    setTimeout(() => {
      self.props.callParent();
    }, 200); 
    return;
  }

  callback(activityId) {
    // console.log('EngageBlock callback', activityId);
  }

  skip() {
    let params = {
      linkedInUserId: this.props.commonData.actAsLinkedInUserId,
      activityId: this.props.activity.id
    };

    let url = '/api/v1/engage/skip';

    let self = this
    axios.put(url, params, ApiHelper.headers()).then(function (response) {
      // console.log('response', response);
      self.setState({ visible: false });
      setTimeout(() => {
        self.props.callParent();
      }, 200); 
    });

    return;
  }

  onPageChange(evt) {
    this.setState({ selectedPage: evt });
  }

  openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  hoverContent() {
    console.log('hover')
    return (
      <div className="flex space-x-2">
        <Tooltip title={locale.t("engage_block.like")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.like") ? 'active' : ''}
            text=""
            icon={<HandThumbUpIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.like"))}
          />
        </Tooltip>
        <Tooltip title={locale.t("engage_block.celebrate")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.celebrate") ? 'active' : ''}
            text=""
            icon={<CakeIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.celebrate"))}
          />
        </Tooltip>
        <Tooltip title={locale.t("engage_block.support")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.support") ? 'active' : ''}
            text=""
            icon={<LifebuoyIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.support"))}
          />
        </Tooltip>
        <Tooltip title={locale.t("engage_block.love")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.love") ? 'active' : ''}
            text=""
            icon={<HeartIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.love"))}
          />
        </Tooltip>
        <Tooltip title={locale.t("engage_block.insightful")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.insightful") ? 'active' : ''}
            text=""
            icon={<LightBulbIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.insightful"))}
          />
        </Tooltip>
        <Tooltip title={locale.t("engage_block.funny")}>
          <LinkButton
            class={this.state.reaction === locale.t("engage_block.funny") ? 'active' : ''}
            text=""
            icon={<FaceSmileIcon width={24} />}
            onClick={(e) => this.like(locale.t("engage_block.funny"))}
          />
        </Tooltip>
      </div>
    )
  }

  commentCallback(promptId, content) {
    this.rewrite(promptId, content);
  }

  render() {
    let activity = this.props.activity;

    return (
      <div id={this.props.id} className={`bg-white mb-6 ${this.state.visible ? '' : 'hidden'}`}>
        <Card>
          <div className="flex flex-col space-y-4">
            <div>{this.props.postBlock}</div>

            <div style={{ fontSize: '13px' }} className="flex justify-between text-gray-500 py-2 border-b border-gray-200">
              <div className="flex items-center space-x-1">
                <img src='/images/like.svg' alt="likes" className="w-4 h-4" />
                <span>{activity.blob.num_likes}</span>
              </div>
              <div>{activity.blob.num_comments} {locale.t("engage_block.comments")}</div>
            </div>

						<div className="flex justify-between items-center space-x-2">
							<Popover content={this.hoverContent} title={locale.t("engage_block.leave_reaction_popover_title")}>
                <div className='do-not-remove'>
                  <LinkButton
                    class={`${this.state.hasReacted ? 'active' : ''} flex items-center justify-center space-x-1 bg-gray-100 px-3 py-2 rounded-lg shadow-sm hover:bg-gray-200 transition duration-150`}
                    text={<span className="inline-flex items-center leading-none">{this.state.reaction}</span>}
                    icon={<span className="inline-flex items-center h-5 w-5 text-gray-500">{this.state.icon}</span>}
                    onClick={(e) => this.like(locale.t("engage_block.like"))}>
                  </LinkButton>
                </div>
							</Popover>

							<LinkButton
								class="flex items-center justify-center space-x-1 bg-gray-100 px-3 py-2 rounded-lg shadow-sm hover:bg-gray-200 transition duration-150"
								text={<span className="inline-flex items-center leading-none">{locale.t("engage_block.open_btn")}</span>}
								icon={<ArrowTopRightOnSquareIcon className="h-5 w-5 text-gray-500" />}
								onClick={(e) => this.openInNewTab(activity.blob.permalink)}
							/>

							<LinkButton
								class="flex items-center justify-center space-x-1 bg-gray-100 px-3 py-2 rounded-lg shadow-sm hover:bg-gray-200 transition duration-150"
								text={<span className="inline-flex items-center leading-none">{locale.t("engage_block.skip_btn")}</span>}
								icon={<ForwardIcon className="h-5 w-5 text-gray-500" />}
								onClick={(e) => this.skip(e)}
							/>

							<LinkButton
								class="hidden md:flex items-center justify-center space-x-1 bg-gray-100 px-3 py-2 rounded-lg shadow-sm hover:bg-gray-200 transition duration-150"
								text={<span className="inline-flex items-center leading-none">{locale.t("engage_block.like_and_skip_btn")}</span>}
								icon={<HandThumbUpIcon className="h-5 w-5 text-gray-500" />}
								onClick={(e) => this.likeAndSkip(e)}
							/>
						</div>


            <div>
              <Editor
                placeholder={this.state.loading ? this.state.loadingText : locale.t("editor.comment_placeholder")}
                content={this.state.comment}
                isComment={true}
                commonData={this.props.commonData}
                onChangeCallback={this.onEditorChange}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Checkbox className='text-gray-500 text-sm' onChange={this.handleCheckboxChange}>
                Save comment as draft post
                <Tooltip title="Some of the best comments provide value even when they stand alone. This is content repurposing at its finest!">
                  <QuestionMarkCircleIcon width="16px" className='ml-1 text-gray-500' style={{ verticalAlign: 'middle' }} />
                </Tooltip>
              </Checkbox>
              {(!this.state.isRepliesPage) && (
                <FlyoutButton prompts={this.props.prompts} commentCallback={(prompt_id) => this.commentCallback(prompt_id, activity.blob.post_content)}>
                </FlyoutButton>
              )}
            </div>

            <div className="flex justify-between mt-2">
              <div className="flex-grow">
                <PagesDropdown pages={this.props.pages} commonData={this.props.commonData} onPageChange={this.onPageChange}/>
              </div>
              <Button onClick={(e) => this.reply(e)} type="primary" className="font-bold py-0">
                {locale.t("engage_block.reply_btn")}
              </Button>
            </div>

          </div>
        </Card>
      </div>
    );
  }
}

export default EngageBlock;

