import React from 'react';
import { Fragment, useState } from 'react';
import { Alert } from 'antd';
import Theme from './Theme';
import Navigation from './Navigation';
import * as CookieHelper from '../Helpers/CookieHelper';
import { Dialog, Menu, Transition } from '@headlessui/react';
import * as Helper from '../Helpers/Helpers';
import {
  Bars3BottomLeftIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

export default function Example(props) {

  // console.log('IS MASTER ADMIN', props.commonData.isMasterAdmin);
  // console.log('ENV', process.env.NODE_ENV);

  // We do not want to trigger this twice, which can happen on the Setup Profile page
  if (!props.muteCookieListener) {
    // console.log('DashboardLayout: add listener - - ');
    CookieHelper.addCookieListener(function (cookieFound) {}, props.commonData.licenseKey);
  } else {
    // console.log('DashboardLayout: DO NOT add listener - - ');
  }

  const billingLink =
    'https://billing.stripe.com/p/login/bIY7vp08f5wDdq07ss?prefilled_email=' +
    props.commonData.currentUserEmail;

  // console.log('dl props', props);
  // console.log('DashboardLayout: needs cookie', props.commonData.needsFreshCookie);
  // console.log('DashboardLayout: acting AS nother lI userb', props.commonData.isActingAsAnotherLiUser);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // const billing_url = Helper.accountSettingsUrl(props.commonData) + 'billing';
  // let userNavigation = [{ name: 'Sign out', href: '/users/sign_out' }];
  //
  // if (props.commonData.isAccountOwner) {
  //   userNavigation.unshift({ name: 'Billing', href: billing_url });
  // }

  return (
    <Theme>
      <div className="h-full">

        {!props.commonData.isActingAsAnotherLiUser && props.commonData.needsFreshCookie && <span id="install-chrome-ext" ></span>}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-white " />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-tvranslate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <Navigation commonData={props.commonData} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:flex-col z-10">
          {!props.isSetup && <Navigation commonData={props.commonData} />}
        </div>

        <div className={`${props.isSetup ? '' : 'md:pl-20'} h-full flex flex-1 flex-col`}>
          <div className="block md:hidden sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1"></div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs border-gray-400 border-2 items-center rounded-full text-sm p-0">
                      <span className="sr-only">Open user menu</span>
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>

            <main className="h-full">

              {props.commonData.showUpgradeBanner && (
                  <div className="flex justify-center items-center bg-brand w-full">
                  <p className="text-sm leading-6 text-center">
                    Surprise! We've upgraded your account for free, to include all features during your trial. <br />
                    <a href={billingLink} style={{ color: '#f5f888' }}> Upgrade now 
                    </a>
                    &nbsp;so you don't lose these features when your trial ends.
                  </p>
                </div>
              )}

              <div className="w-full flex flex-col items-center">
                {!props.hideCookieWarning && props.commonData.needsFreshCookie !== false && (
                  <div className="w-full mb-4 flex justify-center">
                    <Alert
                      type="error"
                      message="ACTION REQUIRED: We cannot access this profile."
                      description={
                        <span>
                          Ensure you:
                          <ul>
                            <li>Are using the Chrome browser</li>
                            <li>Have the Aware Chrome extension enabled</li>
                            <li>Are logged into LinkedIn in this browser</li>
                          </ul>
                          Then reload this page. We are unable to access your profile until this message no longer appears.
                        </span>
                      }
                      banner
                    />
                  </div>
                )}
                <div className={`pb-8 ${props.narrow ? 'w-[620px]' : ' w-full max-w-7xl'}`}>
                  <div className="flex flex-col gap-1">
                    <p className="mb-0 text-gray-800 text-4xl font-black leading-tight tracking-[-0.033em]">
                      {props.title}
                    </p>
                    <p className="mt-0 mb-6 text-xl text-[#5d7183] font-normal leading-normal">
                      {props.subtitle}
                    </p>
                    <div className="w-full">{props.children}</div>
                  </div>
                </div>
              </div>

            </main>

        </div>
      </div>
    </Theme>
  );
}
