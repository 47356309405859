import React, {useState} from 'react';
import DashboardLayout from './Layouts/DashboardLayout';

class Index extends React.Component {

  constructor(props) {
    super(props);
  }

  onClick(e) {
    window.location.href =
      'https://billing.stripe.com/p/login/bIY7vp08f5wDdq07ss?prefilled_email=' +
      this.props.commonData.currentUserEmail;
  }

  render() {
    return (
      <DashboardLayout
        title="Unlock more superpowers."
        subtitle="Choose the plan that's right for you or your team."
        commonData={this.props.commonData}>

				<div class="-mx-4 mt-8 max-w-2xl grid grid-cols-1 lg:grid-cols-3 gap-y-6 sm:mx-auto lg:-mx-8 lg:max-w-none xl:mx-0 xl:gap-x-8">
          {this.props.commonData.currentPlan == 3 && (
            <section class="flex flex-col rounded-3xl m-4 px-6 sm:px-8 bg-gray-400 py-8">
              <h3 class="font-display text-2xl text-white p-0 m-0">Aware Creator</h3>
              <p class="p-4 m-0 font-display text-5xl font-light tracking-tight text-red-700"><span class="font-display text-4xl font-light tracking-tight text-white">$9/mo</span></p>
              <p class="mt-2 text-base text-white">Robust content, engagement, and profile analytics and email digest. Community quick-reply feed.</p>
              <ul role="list" class="pl-0 mt-10 flex flex-col gap-y-3 text-sm text-white">
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Analytics email digest</span>
                 </li>
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Content analytics</span>
                 </li>
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Content engagement analytics</span>
                 </li>
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Profile performance analytics</span>
                 </li>
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Outbound engagement analytics</span>
                 </li>
                 <li class="flex">
                    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                       <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                       <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                    </svg>
                    <span class="ml-4">Quick-reply community feed</span>
                 </li>
              </ul>
              <a class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8" aria-label="Get started with the Aware Creator plan for 9" href="#">Your current plan</a>
           </section>
          )}

         <section class={`flex flex-col rounded-3xl m-4 px-6 sm:px-8 py-8 ${this.props.commonData.currentPlan == 1 ? 'bg-gray-400' : 'bg-blue-400'}`}>
            <h3 class="font-display text-2xl text-white p-0 m-0">Aware Business</h3>
            <p class="p-4 m-0 font-display text-4xl font-light tracking-tight text-red-800"><span class="ml-4 font-display text-4xl font-light tracking-tight text-white">$39/mo</span></p>
            <p class="mt-2 text-base text-white">Analytics and engagement superpowers.</p>
            <ul role="list" class="pl-0 mt-10 flex flex-col gap-y-3 text-sm text-white">
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Everything in Creator plan</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Engage on behalf of your Company/Pages</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Create unlimited custom engagement feeds</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Engagement activity analytics</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Top leads insights</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Top leads engagement feed</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Hand-curated Influencer list</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Hand-curated Influencer list engagement feed</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Quick-response support</span>
               </li>
            </ul>
            {this.props.commonData.currentPlan != 1 && (
              <a class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8" aria-label="Get started with the Aware Business plan for 39" href="#" onClick={() => this.onClick()}>Upgrade</a>
            )}
            {this.props.commonData.currentPlan == 1 && (
              <a class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8" aria-label="Get started with the Aware Business plan for 39" href="#">Your current plan</a>
            )}
         </section>

         <section class="flex flex-col rounded-3xl m-4 px-6 sm:px-8 bg-blue-400 py-8">
            <h3 class="font-display text-2xl text-white p-0 m-0">Aware Teams</h3>
            <p class="p-4 m-0 font-display text-5xl font-light tracking-tight text-red-700"><span class="font-display text-4xl font-light tracking-tight text-white">$49/mo</span></p>
            <p class="mt-2 text-base text-white">Grow your business brand on LinkedIn at lightning speed.</p>
            <ul role="list" class="pl-0 mt-10 flex flex-col gap-y-3 text-sm text-white">
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Everything in Business plan</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Team analytics</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Team analytics email digest</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Native Hubspot integration/sync</span>
               </li>
               <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
                     <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" stroke-width="0"></path>
                     <circle cx="12" cy="12" r="8.25" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                  </svg>
                  <span class="ml-4">Native Salesforce integration/sync</span>
               </li>
            </ul>

            <a class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8" aria-label="Get started with the Aware Teams plan for 49" href="#" onClick={() => this.onClick()}>
              Upgrade
            </a>

         </section>
				</div>
      </DashboardLayout>
    );
  }
}

export default Index;
