import React from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import * as Helper from '../Helpers/Helpers';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { downloadMultipleReports, downloadCsv } from '../Helpers/CsvHelper';
import Spinner from '../Spinner';
import { countMentions, removeNonAlphanumericExceptHash, truncateString } from '../Helpers/StringHelper';
import _ from 'underscore';
import AnalyticsCard from './AnalyticsCard';
import StatisticCard from './StatisticCard';
import BarCard from './BarCard';
import SectionHeader from '../SectionHeader';
import Posts from '../Posts';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Card, Tabs, DatePicker, Tooltip, Badge, Tag, Avatar, Radio, Space, Typography, Button } from 'antd';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import initPostHog from '../PostHog';
import posthog from 'posthog-js';
const { CheckableTag } = Tag;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const axios = require('axios');

import { I18n } from "i18n-js";
import translations from "/app/frontend/translations.json";
const locale = new I18n(translations);

const escapeCSV = (array) => {
  return array.map(value => {
    if (typeof value === 'string' && value.includes(',')) {
      return '"' + value.replace(/"/g, '""') + '"';
    }
    return value;
  });
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale

    // remove this before long, just trying to expedite this since it otherwise happens on login
    initPostHog();
    posthog.identify(
      gon.user_id,
      { email: gon.user_email }
    );

    this.state = {
      activities_sent: this.props.activities_sent,
      recentActivity: this.props.recent_activity,
      liUsers: this.props.liUsers,
      selectedLiUsers: [this.props.commonData.actAsLinkedInUserId],
      selectedTags: [],
      postTags: this.props.tags,
      loading: true,
      isDownfalse: false,
      isDownloadBtnEnabled: true,
      haveSubsequentSelection: false,
      displayPosts: true
    };
    this.callApi = this.callApi.bind(this);
    this.callback = this.callback.bind(this);
    this.customDateChange = this.customDateChange.bind(this);
    this.areDatesMoreThanAYearApart = this.areDatesMoreThanAYearApart.bind(this);
    this.badge = this.badge.bind(this);
  }
  
  async callApi() {

    if (this.state.jobInProgress) {
      this.setState({ haveSubsequentSelection: true });
      console.log('Job in progress, skipping new job');
      return;
    }

    this.setState({ loading: true, jobInProgress: true });

    const { selectedLiUsers, selectedTags } = this.state;

		let startDate, endDate;
    if (this.state.dateRangePreset == 'custom') {
      startDate = this.state.startDate;
      endDate = this.state.endDate;
      // console.log('startDate', startDate, 'endDate', endDate);
      if (startDate == undefined || endDate == undefined) {
        console.log('returning');
        return;
      }
    }

    let params = { 
      selectedTags: this.state.selectedTags, 
      dateRange: this.state.dateRangePreset, 
      startDate: startDate, 
      endDate: endDate, 
      selectedUsers: this.state.selectedLiUsers 
    };

    const url = '/0/' + this.props.commonData.currentAccountUniqueToken + '/dashboard/setup_data';

    try {
      ApiHelper.prepHttpCall();
			const response = await axios.post(url, params, {
				headers: {
					accept: 'application/json',
				}
			});
      this.setState({ currentJobId: response.data.job_id });

      console.log('Job started:', response.data.job_id, response);
      if (response.data.job_id) {
        console.log('Job started:', response.data.job_id, response);
        Helper.startReportPolling(this.props.commonData, response.data.job_id, this.callback);
      }
    } catch (error) {
      console.error('API call failed:', error);
      this.setState({ loading: false, jobInProgress: false });
    }
  }

  callback(jobId, data) {
    // console.log('Job complete: ', jobId, data);
    this.setState({
      loading: false,
      jobInProgress: false,
      reportData: data.data.report_data,
      posts: data.data.posts,
    });
    if( this.state.haveSubsequentSelection) {
      this.callApi();
      this.setState({ haveSubsequentSelection: false });
    }
  }

  onUserChange(e, checked) {
    const { selectedLiUsers } = this.state;
    let updatedLiUsers = checked
      ? [...selectedLiUsers, e]
      : selectedLiUsers.filter((id) => id !== e);

    if (updatedLiUsers.length == 0) {
      updatedLiUsers = [this.props.commonData.linkedInUserId]
    }

    this.setState({ selectedLiUsers: updatedLiUsers }, () => {
      this.callApi();  // Start a new job
    });
  }

  componentDidMount() {
    // console.log('Dashboard mounted');
    this.callApi()
  }

  pollDownloadApi = async () => {
    ApiHelper.prepHttpCall();
    const userId = this.state.selectedLiUsers.length > 0 ? this.state.selectedLiUsers.join(',') : null
    let url = '/0/' + this.props.commonData.currentAccountUniqueToken + '/dashboard/download_report'
    if (userId) {
      url += `?selectedUsers=${userId}`
    }

    try {
      const response = await axios.get(url, {
        headers: {
          accept: 'application/json',
        }
      });
      const resData = response.data
      const allReportStatus = [...new Set(resData?.map(x => x.report.status))]
      // console.log('resData', allReportStatus, resData)
      if (
        response.status === 200 &&
        allReportStatus.length === 1 &&
        allReportStatus.includes('completed')) {

        const csvContents = resData.map(x => {
          let csvContent = ''
          const csvHeaders = [
            ['Profile Name', x?.li_user_name],
            ['Profile URL', `https://linkedin.com/in/${x?.li_user_identifier}`],
          ]
          csvContent += csvHeaders.map((row) => row.join(",")).join("\n")
          csvContent += '\n\n'
          csvContent += this.generateDataCsv(JSON.parse(x.report?.report_data));
          return {
            name: `${x?.li_user_identifier}.csv`,
            content: csvContent
          }
        })

        // console.log('csvContents', csvContents)
        downloadMultipleReports(csvContents,'linkedin-report.zip')
        this.setState({ isDownloadingReport: false })

      } else {
        return setTimeout(() => this.pollDownloadApi(), 1000);
      }
    } catch (error) {
      console.error(error);

    }
  }

  shortenStr(msg) {
    if (msg.length > 140) {
      msg = msg.substring(0, 140) + '...';
    }
    return msg;
  }

  greeting() {
    if (this.props.commonData.sfdcName === null) {
      return '';
    } else {

      return locale.t("performance.greeting") + ' ' + this.props.commonData.sfdcName;
    }
  }

  onDateRangeChange(e) {
    console.log("onDateRangeChange", e.target.value)

    this.setState({ dateRangePreset: e.target.value },
      () => { 
        if(e.target.value != 'custom') {
          console.log('not custom')
          this.callApi()
        }
      }
    );
  }

  customDateChange(e, dateString) {
    let start = dateString[0]
    let end = dateString[1]

    if (!start || !end) {
      // Occurs when clearing custom dates
      console.log('customDateChange: no dates');
      return; 
    }
    console.log('customDateChange', dateString)

    if (this.areDatesMoreThanAYearApart(start, end)) {
      this.setState({ displayPosts: false });
    }

    this.setState({ startDate: start, endDate: end },
      () => this.callApi()
    );
  }

  areDatesMoreThanAYearApart(sd, ed) {

    const startDate = new Date(sd);
    const endDate = new Date(ed);

    const diffInYears = endDate.getFullYear() - startDate.getFullYear();

    const oneYearApart = diffInYears > 1 || 
        (diffInYears === 1 && 
         (endDate.getMonth() > startDate.getMonth() || 
          (endDate.getMonth() === startDate.getMonth() && endDate.getDate() >= startDate.getDate())));

    return oneYearApart;
  }


  onTagClick(e, checked) {
    let self = this

    let newSelectedTags = this.state.selectedTags.includes(e)
      ? this.state.selectedTags.filter((fc) => fc !== e)
      : [...this.state.selectedTags, e]

    if (newSelectedTags.length == 0) {
      newSelectedTags = []
    }

    this.setState({
      selectedTags: newSelectedTags
    },
      () => self.callApi()
    )
  }

  phrase(activity) {
    switch (activity.event_type) {
      case 'post_comment':
        return this.post_comment_summary(activity);
      case 'post_like':
        return this.post_like_summary(activity);
      default:
        return activity.key;
    }
  }

  post_comment_summary(activity) {
    // console.log('activity', activity);
    let avatar = <Avatar src={activity.owner.headshot_url} />;
    let str =
      activity.owner.full_name +
      ' ' + locale.t("performance.commented") + ' ' +
      this.snipped_content(activity.blob.comment) +
      ' ' + locale.t("performance.on_your_post") + ' ' +
      this.snipped_content(activity.blob.post_content);
    return (
      <div style={{ display: 'table' }}>
        <div
          className="avatar-x-large"
          style={{ display: 'table-cell', padding: '0 8px', verticalAlign: 'middle' }}>
          {avatar}
        </div>
        <div style={{ display: 'table-cell', padding: '0 12px', verticalAlign: 'middle' }}>
          {str}
        </div>
      </div>
    );
  }

  post_like_summary(activity) {
    let avatar = <Avatar src={activity.owner.headshot_url} />;
    let str =
      activity.owner.full_name +
      ' ' + locale.t("performance.liked_your_post") + ' ' +
      this.snipped_content(activity.blob.post_content);
    return (
      <div style={{ display: 'table' }}>
        <div
          className="avatar-x-large"
          style={{ display: 'table-cell', padding: '0 8px', verticalAlign: 'middle' }}>
          {avatar}
        </div>
        <div style={{ display: 'table-cell', padding: '0 12px', verticalAlign: 'middle' }}>
          {str}
        </div>
      </div>
    );
  }

  snipped_content(content) {
    return content ? content.substring(0, 70) + '...' : '';
  }

  generateDataCsv(data) {
    const reportData = data;
    const columnName = [
      [
        "Month",
        "Posts",
        "Impressions",
        "Shares",
        "New Connections",
        "Num. People Messaged",
        "Avg Engagement Rate",
        "Comments Received",
        "Comments Left",
        "Reactions Received",
        "Reactions Left",
      ],
    ];
    const rowData = Object.entries(reportData).map(([month, x]) => {
      return [
        month,
        x.posts,
        x.contentViews,
        x.shares,
        x.newConnections,
        x.messages,
        x.engagementRate,
        x.commentsReceived,
        x.commentsLeft,
        x.likesReceived,
        x.likesLeft
      ]
    })

    return [
      ...columnName,
      ...rowData
    ].map((row) => escapeCSV(row).join(",")).join("\n");
  }

  generateCsv(data, {
    li_user_name, li_link
  }) {
    let csvContent = ''
    const csvHeaders = [
      ['Profile Name', li_user_name],
      ['Profile URL', li_link],
    ]
    csvContent += csvHeaders.map((row) => row.join(",")).join("\n")
    csvContent += '\n\n'
    csvContent += this.generateDataCsv(data)

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'linkedin-report.csv');
    document.body.appendChild(link);
    link.click()
    document.body.removeChild(link);
    this.setState({ isDownloadingReport: false })
  }

  generatePostCsv() {
    const postData = this.state.posts
    const columnName = [
      [
        'summary',
        'type',
        'firstname',
        'lastname',
        'numViews',
        'numLikes',
        'numComments',
        'numShares',
        'engagementRate',
        'numMentions',
        'numHashtags',
        'hashtags',
        'labels',
        'createdAt',
        'link'
      ]
    ]

    // console.log('postData', Object.keys(postData[0]), this.props.commonData)
    // console.log('postData', postData.length, postData)

    const rowData = Object.entries(postData).map(([month, x]) => {
      const postObject = x.blob
      let postType = 'Text'
      if(postObject.post_image?.length > 0) {
        postType = 'Image'
      }else if(postObject.post_video?.length > 0) {
        postType = 'Video'
      }else if(postObject.post_document?.length > 0) {
        postType = 'Document'
      }else if(postObject.post_poll?.length > 0) {
        postType = 'Poll'
      }else if(postObject.is_newsletter) {
        postType = 'Newsletter'
      }else if(postObject.post_article?.length > 0) {
        postType = 'Article'
      }
      // console.log('postType', postType, postObject)
      const postPermalink = postObject.permalink
      // const postUrn = postPermalink.split('/')[5]
      const postContentPlain = removeNonAlphanumericExceptHash(postObject.post_content)
      const postHashtags = postContentPlain?.match(/#(\w+)/g) || []
      const postSummary = truncateString(postContentPlain, 100)
      return [
        // postUrn,
        postSummary,
        postType,
        x.owner.first_name,
        x.owner.last_name,
        postObject.num_views,
        postObject.num_likes,
        postObject.num_comments,
        postObject.num_shares,
        Number(x.engagement_rate) ? `${Number(x.engagement_rate)}%` : 0,
        countMentions(postContentPlain),
        postHashtags.length,
        postHashtags?.join(', '),
        x.tags?.map((tag) => tag.tag_name).join(', '),
        x.event_date_raw,
        postPermalink
      ]
    })
    // console.log('rowData', rowData.length, rowData)
    const csvContent = [
      ...columnName,
      ...rowData
    ].map((row) => escapeCSV(row).join(",")).join("\n");
    // console.log('csvContent', csvContent.length, csvContent)

    downloadCsv(csvContent, 'post-report.csv')
  }

  downloadReport() {
    if (this.state.isDownloadingReport) return;
    this.setState({ isDownloadingReport: true })
    this.pollDownloadApi()
  }

  inviteUser() {
    let url = Helper.accountSettingsUrl(this.props.commonData) + 'users'
    window.location.href = url
  }

  badge(value) {
    let bgColor = value >= 0 ? 'rgb(15 137 19 / 15%)' : 'rgb(255 228 228)';
    let color = value >= 0 ? 'green' : 'red';

    return (
      <Badge
        count={value + '%'}
        overflowCount={1000000}
        color={bgColor}
        style={{ color: color }}
      />
    )
  }

  render() {
    const tip = <Tooltip title={locale.t("performance.post_tag_tooltip")} >
      <QuestionMarkCircleIcon width="20px" style={{ color: 'gray', verticalAlign: 'middle' }} />
    </Tooltip>
    const recent_activity_columns = [
      {
        title: locale.t("performance.date_col_header"),
        dataIndex: 'event_date',
        key: 'event_date',
        width: 200,
        defaultSortOrder: 'descend',
        sorter: (a, b) => Date.parse(a.event_date) - Date.parse(b.event_date),
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: locale.t("performance.details_col_header"),
        render: (blob) => {
          return this.phrase(blob);
        }
      },
      {
        title: '',
        width: 150,
        align: 'center',
        render: (a) => {
          if (a.blob['permalink'] != undefined) {
            return (
              <a href={a.blob['permalink']} target="_blank">
                View
              </a>
            );
          }
        }
      }
    ];

    return (
      <DashboardLayout
        title={locale.t("performance.title")}
        subtitle={locale.t("performance.subtitle")}
        commonData={this.props.commonData}>

        <div style={{ marginBottom: 24, display: 'flex', justifyContent: 'space-between' }}>
          <div className='w-full'>
            {_.contains(this.props.commonData.availableFeatures, 'team_dashboard') && this.props.commonData.currentUserRole != 'individual_contributor' && (
              <SectionHeader size="small" title="Team members"> </SectionHeader>
            )}

            <div className="flex">
              <div className="flex-1">

                {_.contains(this.props.commonData.availableFeatures, 'team_dashboard') && this.props.commonData.currentUserRole != 'individual_contributor' && (
                  <>
                    {this.state.liUsers.map((liUser) => (
                      <CheckableTag
                        key={liUser[0]}
                        className="px-3 py-1"
                        checked={this.state.selectedLiUsers.includes(liUser[0])}
                        onChange={(checked) => this.onUserChange(liUser[0], checked)}
                      >
                        {liUser[1]}
                      </CheckableTag>
                    ))}
                    <Tag onClick={() => this.inviteUser()} className='border-0 bg-slate-100 px-2 py-1 cursor-pointer'>
                      {locale.t("performance.invite_user_tag")}
                    </Tag>
                  </>
                )}
              </div>

              <div className="flex-none flex-col items-end hidden sm:flex">
                <Space>

                  <Button
                    disabled={this.state.isDownloadingReport || !this.state.isDownloadBtnEnabled}
                    onClick={() => this.downloadReport()} className='px-4 py-1 cursor-pointer'>
                    {this.state.isDownloadingReport ? <LoadingOutlined className='mr-2' /> : <DownloadOutlined className='mr-2' />}
                    Download Report
                  </Button>

                </Space>
              </div>

            </div>
          </div>
        </div>

        <div style={{ marginBottom: 24 }}>
          <div>
            {this.state.postTags.length > 0 && (
              <>
                <SectionHeader size="small" title={<>Tags {tip}</>}> </SectionHeader>
              </>
            )}
          </div>
          {this.state.postTags.map((tag) => (
            <CheckableTag
              key={tag.id}
              className="px-3 py-1"
              checked={this.state.selectedTags.includes(tag.id)}
              onChange={(checked) => this.onTagClick(tag.id, checked)}
            >
              {tag.tag_name}
            </CheckableTag>
          ))}
        </div>

        {this.state.loading && <Spinner title="Generating report" size="small" />}

        <div className="mb-8 grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-5 gap-4">
          <div>
            <StatisticCard
              title={locale.t("performance.connections_card_title")}
              tooltip="Total"
              data={this.state.reportData?.totalConnections}
            />
          </div>
          <div>
            <StatisticCard
              title={locale.t("performance.search_appearances_card_title")}
              data={this.state.reportData?.searchAppearances}
              tooltip="Last 7 days (approx.)"
            />
          </div>
          <div>
            <StatisticCard
              title={locale.t("performance.profile_views_card_title")}
              data={this.state.reportData?.profileViews}
              tooltip="Last 90 days"
            />
          </div>
          <div>
            <StatisticCard
              title={locale.t("performance.follower_count_card_title")}
              tooltip="Total" data={this.state.reportData?.followers} />
          </div>
          <div>
            <StatisticCard
              title={locale.t("performance.following_count_card_title")}
              tooltip="Total" data={this.state.reportData?.following} />
          </div>
        </div>

        <SectionHeader size="small" title={locale.t("performance.date_range")}></SectionHeader>

        <Radio.Group className="" defaultValue="1week" style={{ marginBottom: 24 }} onChange={(e) => this.onDateRangeChange(e)} buttonStyle="solid">
          <Radio.Button value="1week">{locale.t("performance.one_week")}</Radio.Button>
          <Radio.Button value="4week">{locale.t("performance.four_week")}</Radio.Button>
          <Radio.Button value="mtd">{locale.t("performance.mtd")}</Radio.Button>
          <Radio.Button value="qtd">{locale.t("performance.qtd")}</Radio.Button>
          <Radio.Button value="ytd" className="rounded-r-full sm:rounded-none">{locale.t("performance.ytd")}</Radio.Button>
          <Radio.Button value="weekly" className="hidden sm:inline-block">{locale.t("performance.weekly")}</Radio.Button>
          <Radio.Button value="monthly" className="hidden sm:inline-block">{locale.t("performance.monthly")}</Radio.Button>
          <Radio.Button value="custom" className="hidden sm:inline-block">{locale.t("performance.custom")}</Radio.Button>
        </Radio.Group>

        <RangePicker onChange={this.customDateChange} className={`ml-4 ${this.state.dateRangePreset == 'custom' ? "" : "hidden"}`} />

        <Tabs className="mb-8" defaultActiveKey="1">

          <Tabs.TabPane tab={locale.t("performance.overview_tab")} key="marketing">

            <div className="grid gap-4">

              <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.posts_card_title")}
                    toolTip="The number of posts published on the personal LinkedIn profile(s) selected above, excluding reposts"
                    data={this.state.reportData?.posts} />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.views_card_title")}
                    toolTip="The number of impressions, as calculated by LinkedIn, on all of the Posts published during the date range. Note that these are calculated as of the Post Date, since we don't know the date of each individual impression."
                    data={this.state.reportData?.contentViews} />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.shares_card_title")}
                    toolTip="The number of times that your Posts published during the selected date range were Shared. This is calculated based on the date of the Post, not the date on which the Share occurred."
                    data={this.state.reportData?.shares}
                  />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.engagement_rate_card_title")}
                    showSingleDate={true}
                    toolTip="The number of reactions and comments on all Posts published during the selected date range, divided by the number of impressions. The date used is the Post Published date, not the date of the reaction or comment."
                    data={this.state.reportData?.engagementRate}
                    format='percentage'
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.comments_received_card_title")}
                    toolTip="The number of comments received on all Posts published during the selected date range, including self-comments."
                    data={this.state.reportData?.commentsReceived}
                  />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.likes_received_card_title")}
                    toolTip="The number of reactions received by other users on all Posts published during the selected date range."
                    data={this.state.reportData?.likesReceived} />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.comments_left_card_title") || "Comments left"}
                    toolTip="The total number of comments published by the selected user(s) during the date range, on all posts (including their own)."
                    data={this.state.reportData?.commentsLeft} />
                </div>
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.likes_left_card_title") || "Likes left"}
                    toolTip="The total number of reactions left by the selected user(s) during the date range, on all posts."
                    data={this.state.reportData?.likesLeft} />
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
                <div>
                  <AnalyticsCard
                    title={locale.t("performance.new_connections_card_title")}
                    toolTip="The number of new connections added to the selected user(s) network during the date range. Note that we cannot currently distinguish between an inbound and an outbound connection request; we only detect the addition of a new connection to the user's network."
                    data={this.state.reportData?.newConnections}
                  />
                </div>
                <div>
                  <StatisticCard
                    tooltip={this.badge(this.state.reportData?.messages?.percentChange)}
                    toolTip="The number of unique people that have exchanged direct messages with the selected user(s), excluding InMails, during the selected date range. For example, a conversation consisting of 5 back-and-forth messages between the user and one single other person, counts as 1 for the purposes of this calculation."
                    title={locale.t("performance.people_messaged_card_title")}
                    data={this.state.reportData?.messages?.currentPeriodValue} />
                </div>
              </div>

            </div>
          </Tabs.TabPane>

          {_.contains(this.props.commonData.availableFeatures, 'team_dashboard') && (
            <Tabs.TabPane tab={locale.t("performance.team_breakdown_tab")} key="sales">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div>
                  <BarCard data={this.state.reportData?.newConnections?.teamBreakdown}
                    title={locale.t("performance.new_connections_card_title")}
                  />
                </div>
                <div>
                  <BarCard data={this.state.reportData?.commentsLeft?.teamBreakdown}
                    title={locale.t("performance.comments_left_card_title")}
                  />
                </div>
                <div>
                  <BarCard data={this.state.reportData?.messages?.teamBreakdown}
                    title={locale.t("performance.prospects_messaged_card_title")}
                  />
                </div>
                <div>
                  <BarCard data={this.state.reportData?.posts?.teamBreakdown}
                    title={locale.t("performance.posts_published_card_title")}
                  />
                </div>
                <div>
                  <BarCard data={this.state.reportData?.commentsReceived?.teamBreakdown}
                    title={locale.t("performance.comments_received_card_title")}
                    subtitle={locale.t("performance.comments_received_card_subtitle")}
                  />
                </div>
                <div>
                  <BarCard data={this.state.reportData?.contentViews?.teamBreakdown}
                    title={locale.t("performance.post_views_card_title")}
                    subtitle={locale.t("performance.post_views_card_subtitle")}
                  />
                </div>
              </div>

            </Tabs.TabPane>
          )}

        </Tabs>

        <SectionHeader
          size="small"
          title={locale.t("performance.posts_from_period_header")}
          right={<Button 
            disabled={!this.state.posts?.length}
            onClick={() => this.generatePostCsv()}>
              Download post data as CSV
            </Button>}
        />

        <Card className="overflow-hidden relative">
          <div className="overflow-x-auto whitespace-nowrap ios-scroll">
            { !this.state.displayPosts && (
              <div class="bg-yellow-100 border-l-4 border-yellow-500 text-slate-700 px-2 py-1 mb-4 italic" role="alert">
                <p>
                  Posts cannot be displayed for date ranges greater than 1 year. Please select a shorter date range to view posts.
                </p>
              </div>
            )}
            <Posts posts={this.state.posts} userid={this.props.commonData.linkedInUserId} commonData={this.props.commonData} tags={this.props.tags} />
          </div>
        </Card>
      </DashboardLayout>
    );
  }
}

export default Dashboard;
