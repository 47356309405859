
import React from 'react';
import axios from 'axios';
import ProfileToggle from '../ProfileToggle';
import * as Helper from '../Helpers/Helpers';
import * as ApiHelper from '../Helpers/ApiHelpers';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import EngageBlock from '../EngageBlock';
import EmptyContent from '../EmptyContent';
import { Button } from 'antd';
import Spinner from '../Spinner';
import PostBlock from './PostBlock';
import DashboardLayout from '../Layouts/DashboardLayout';
import Filters from './Filters';
import ProgressBar from '../ProgressBar';
import Card from '../Card';

import { I18n } from 'i18n-js';
import translations from '/app/frontend/translations.json';
const locale = new I18n(translations);

class Index extends React.Component {
  constructor(props) {
    super(props);
    locale.locale = props.commonData.locale;

    this.state = {
      selectedListValue: this.props.selectedListValue,
      pageName: this.props.pageName,
      hideListSelect: this.props.hideListSelect || false,
      records: [],
      counts: [],
      pageSize: 10,
      start: 0,
      datePublishedDays: props.commonData.feedSettings.publishedWithinDays,
      maxPostsPerUser: this.props.commonData.feedSettings.maxPostsPerUser,
      sortBy: this.props.commonData.feedSettings.sortBy,
      loading: true,
      isFetching: false,
      hasMoreResults: true,
      weeklyCommentGoal: this.props.commonData.weeklyCommentGoal,
      monthlyPostingGoal: this.props.commonData.monthlyPostingGoal,
      weeklyCommentGoalProgress: this.props.commonData.weeklyCommentGoalProgress,
      monthlyPostingGoalProgress: this.props.commonData.monthlyPostingGoalProgress,
    };

    this.onListChange = this.onListChange.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
    this.onMaxPostsPerUserChange = this.onMaxPostsPerUserChange.bind(this);
    this.onPublishedDateChange = this.onPublishedDateChange.bind(this);
    this.onSortByChange = this.onSortByChange.bind(this);
    this.getRecords();
    
    this.scrollTo = this.scrollTo.bind(this);
  }

  handleOnScroll(e) {
    if (!this.state.hasMoreResults) {
      return;
    }
    this.setState(
      {
        isFetching: true,
        start: this.state.start + this.state.pageSize,
      },
      () => this.getRecords()
    );
  }

  getRecords() {
    const url = '/api/v1/lists/';
    const params = {
      listId: this.state.selectedListValue,
      listType: this.props.listType,
      publishedDate: this.state.datePublishedDays,
      maxPostsPerUser: this.state.maxPostsPerUser,
      sortBy: this.state.sortBy,
      start: this.state.start,
      count: this.state.pageSize,
    };

    axios.get(url, { params, headers: ApiHelper.headers() }).then((response) => {
      const hasMoreResults = response.data.records.length > 0;
      this.setState(
        {
          counts: response.data.counts,
          records: this.state.records.concat(response.data.records),
          people_to_lists: response.data.people_to_lists,
          isFetching: false,
          loading: false,
          hasMoreResults: hasMoreResults,
        },
        () => this.onRecordsFetched()
      );
    });
  }

  // scrollTo(id) {
  //   console.log('scrolling to next element after', id);
  //
  //   // Find the index of the current record
  //   const currentIndex = this.state.records.findIndex(record => record.id === id);
  //
  //   // Check if there's a next item in the list
  //   if (currentIndex !== -1 && currentIndex < this.state.records.length - 1) {
  //     // Get the next record in the list
  //     const nextRecordId = this.state.records[currentIndex + 1].id;
  //     const nextElement = document.getElementById(`engage-block-${nextRecordId}`);
  //
  //     if (nextElement) {
  //       // Scroll to the next element
  //       console.log('Scrolling to next element:', nextRecordId);
  //       nextElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     } else {
  //       console.log('Next element not found:', nextRecordId);
  //     }
  //   } else {
  //     console.log('No next element to scroll to');
  //   }
  // }

  scrollTo(id) {
    console.log('scrolling to', id);
    // Find the index of the current record
    const currentIndex = this.state.records.findIndex(record => record.id === id);

    // Function to check if an element is visible
    const isVisible = (element) => {
      return element && element.offsetParent !== null; // If the element has an offsetParent, it is visible
    };

    // Loop through the records starting from the next one
    for (let i = currentIndex + 1; i < this.state.records.length; i++) {
      const nextRecordId = this.state.records[i].id;
      const nextElement = document.getElementById(`engage-block-${nextRecordId}`);

      // Check if the next element is visible
      if (nextElement && isVisible(nextElement)) {
        nextElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
    }
  }

  onComment() {
    this.setState({
      weeklyCommentGoalProgress: this.state.weeklyCommentGoalProgress + 1,
    });
  }

  onRecordsFetched() {
    const blocks = this.state.records.map((x) => {
      
      return (
        <EngageBlock
          key={x.id}
          id={`engage-block-${x.id}`}
          prompts={this.props.prompts}
          callParent={() => this.scrollTo(x.id)}
          onComment={() => this.onComment()}
          commonData={this.props.commonData}
          postBlock={
            <PostBlock
              people_to_lists={this.state.people_to_lists}
              lists={this.props.lists}
              customLists={this.props.customLists}
              activity={x}
              commonData={this.props.commonData}
            />
          }
          pages={this.props.pages}
          activity={x}
        />
      )
    })

    this.setState({ blocks });
  }

  onListChange(value) {
    if (value === 'addedit') {
      const url = Helper.profileSettingsUrl(this.props.commonData) + 'lists/';
      window.location.href = url;
      return;
    }

    this.setState(
      {
        records: [],
        blocks: [],
        selectedListValue: value,
        loading: true,
      },
      () => {
        this.getRecords();
      }
    );
  }

  onSortByChange(evt) {
    this.setState(
      {
        loading: true,
        records: [],
        blocks: [],
        start: 0,
        sortBy: evt,
      },
      () => this.getRecords()
    );
  }

  onMaxPostsPerUserChange(evt) {
    this.setState(
      {
        records: [],
        blocks: [],
        loading: true,
        start: 0,
        maxPostsPerUser: evt,
      },
      () => this.getRecords()
    );
  }

  onPublishedDateChange(evt) {
    this.setState(
      {
        records: [],
        blocks: [],
        loading: true,
        start: 0,
        datePublishedDays: evt,
      },
      () => this.getRecords()
    );
  }

  addEditLists(e) {
    const url = Helper.profileSettingsUrl(this.props.commonData) + 'lists/';
    window.location.href = url;
  }

  render() {
    return (
      <DashboardLayout
        narrow={true}
        pageClass="my-list"
        title={this.props.title}
        subtitle={this.props.subtitle}
        commonData={this.props.commonData}
      >
          {this.props.commonData.currentUserRole === 'collaborator' && (
            <Card>
              <ProfileToggle
                commonData={this.props.commonData}
                profiles={this.props.commonData.profiles}
                selectedProfile={this.props.commonData.actAsLinkedInUserId}
                style={{ height: 49 }}
              />

              <div className="my-0 space-y-6 xl:space-y-0">
                <div className="mb-6">
                  <ProgressBar
                    weeklyCommentGoal={this.state.weeklyCommentGoal}
                    weeklyCommentGoalProgress={this.state.weeklyCommentGoalProgress}
                    monthlyPostingGoal={this.state.monthlyPostingGoal}
                    monthlyPostingGoalProgress={this.state.monthlyPostingGoalProgress}
                  />
                </div>
              </div>

            </Card>
          )}

         <div className="text-right mb-4">
          {this.props.listType != 'influencers' && (
            <Button type='primary' onClick={() => this.addEditLists()}>{locale.t("my_lists.action_bar.add_edit_lists_btn")}</Button>
          )}
        </div>
        <div className="my-8">
          <Filters
            listType={this.props.listType}
            addEditLists={this.addEditLists.bind(this)}
            hideListSelect={this.state.hideListSelect}
            selectedListValue={this.state.selectedListValue}
            onListChange={this.onListChange.bind(this)}
            lists={this.props.lists}
            sortBy={this.state.sortBy}
            onSortByChange={this.onSortByChange.bind(this)}
            maxPostsPerUser={this.state.maxPostsPerUser}
            onMaxPostsPerUserChange={this.onMaxPostsPerUserChange.bind(this)}
            datePublishedDays={this.state.datePublishedDays}
            onPublishedDateChange={this.onPublishedDateChange.bind(this)}
          />
        </div>


        <div className="w-full m-0 p-0">
          {this.state.blocks}

          {this.state.loading && (
            <div>
              <Spinner title={locale.t('common.fetching_posts')} />
            </div>
          )}

          {!this.state.loading && this.state.records.length === 0 && (
            <EmptyContent message={this.props.emptyContentMessage} />
          )}
        </div>

        {this.state.isFetching && (
          <div>
            <Spinner title={locale.t('common.fetching_posts')} />
          </div>
        )}
        <BottomScrollListener onBottom={this.handleOnScroll} offset={299} />
      </DashboardLayout>
    );
  }
}

export default Index;

