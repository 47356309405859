import React from 'react';
import { Badge, Row, Col, Tooltip, Typography } from 'antd';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ResponsiveContainer, AreaChart, Tooltip as RechartsTooltip, Area, XAxis, YAxis } from 'recharts';
const { Text, Title } = Typography;

class AnalyticsCard extends React.Component {
  constructor(props) {
    super(props);

    console.log('props', props);
    this.state = {
      chartData: props.data?.chartData,
      percentChange: Math.round(props.data?.percentChange || 0),
      // originalPercentChange: Math.round(props.data?.percentChange),
      // originalPreviousPeriodValue: props.data?.previousPeriodValue,
      // originalCurrentPeriodValue: props.data?.currentPeriodValue,
      previousPeriodValue: props.data?.previousPeriodValue,
      previousPeriodStartDate: props.data?.previousPeriodStartDate,
      previousPeriodEndDate: props.data?.previousPeriodEndDate,
      currentPeriodValue: props.data?.currentPeriodValue,
      currentPeriodStartDate: props.data?.currentPeriodStartDate,
      currentPeriodEndDate: props.data?.currentPeriodEndDate,
      previousPeriodActiveDate: props.data?.previousPeriodEndDate,
      currentPeriodActiveDate: props.data?.currentPeriodEndDate
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        chartData: this.props.data?.chartData,
        // originalPercentChange: Math.round(this.props.data?.percentChange),
        // originalPreviousPeriodValue: this.props.data?.previousPeriodValue,
        // originalCurrentPeriodValue: this.props.data?.currentPeriodValue,
        percentChange: Math.round(this.props.data?.percentChange || 0),
        previousPeriodValue: this.props.data?.previousPeriodValue,
        previousPeriodStartDate: this.props.data?.previousPeriodStartDate,
        previousPeriodEndDate: this.props.data?.previousPeriodEndDate,
        previousPeriodActiveDate: this.props.data?.previousPeriodEndDate,
        currentPeriodValue: this.props.data?.currentPeriodValue,
        currentPeriodStartDate: this.props.data?.currentPeriodStartDate,
        currentPeriodEndDate: this.props.data?.currentPeriodEndDate,
        currentPeriodActiveDate: this.props.data?.currentPeriodEndDate
      });
    }
  }

  badge(value) {
    const bgColor = value >= 0 ? 'rgb(15 137 19 / 15%)' : 'rgb(255 228 228)';
    const color = value >= 0 ? 'green' : 'red';

    return (
      <Badge
        count={`${value}%`}
        overflowCount={1000000}
        style={{ backgroundColor: bgColor, color: color }}
      />
    );
  }

  resetData = () => {
    this.setState({
      currentPeriodValue: this.props.data?.currentPeriodValue,
      previousPeriodValue: this.props.data?.previousPeriodValue,
      percentChange: Math.round(this.props.data?.percentChange || 0),
      previousPeriodEndDate: this.props.data?.previousPeriodEndDate,
      currentPeriodEndDate: this.props.data?.currentPeriodEndDate,
    });
  };

  customTooltip = ({ active, payload }) => {
    // console.log('payload', payload);
    if (active && payload && payload.length) {
      // console.log('in');
      payload = payload[1].payload;
      // Do not directly update state here. Instead, use temporary variables.
      const currentPeriodValue = payload.currentPeriodValue || 0;
      const previousPeriodValue = payload.previousPeriodValue || 0;
      let percentChange = 0;

      if (previousPeriodValue > 0) {
        percentChange = ((currentPeriodValue - previousPeriodValue) / previousPeriodValue) * 100;
      } else if (currentPeriodValue > 0) {
        percentChange = 100;
      }

      if (this.state.previousPeriodActiveDate != payload.previousPeriodDate) {
        // You can choose to update the state here, but it must be done asynchronously.
        console.log('updating state');
        this.setState({
          currentPeriodValue: currentPeriodValue.toLocaleString(),
          previousPeriodValue: previousPeriodValue.toLocaleString(),
          percentChange: Math.round(percentChange),
          previousPeriodActiveDate: payload.previousPeriodDate,
          currentPeriodActiveDate: payload.currentPeriodDate
        });
      }
    }

    return null;
  };

  render() {
    const { className, title, format, showSingleDate } = this.props;
    const {
      percentChange,
      previousPeriodValue,
      previousPeriodStartDate,
      previousPeriodEndDate,
      currentPeriodValue,
      currentPeriodStartDate,
      currentPeriodEndDate,
      previousPeriodActiveDate,
      currentPeriodActiveDate
    } = this.state;

    return (
      <div className={`${className} rounded-lg bg-card px-4 py-4 border-solid border-secondary statistic`}>
        <Row>
          <Col span={18}>

            <div className="flex items-center">
              <Title level={5} className="m-0">{title}</Title>
              { this.props.toolTip && (
                <Tooltip className="ml-2" title={ this.props.toolTip }>
                  <QuestionMarkCircleIcon className="ml-1 w-4 h-4" />
                </Tooltip>
              )}
            </div>

          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {this.badge(percentChange)}
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Text className="stat" style={{ color: '#b4d5d7' }}>
              {previousPeriodValue}
              {format === 'percentage' && '%'}
            </Text>
            <Text className="stat-date">
              {!showSingleDate ? `${previousPeriodStartDate} - ${previousPeriodActiveDate}` : previousPeriodActiveDate}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Text className="stat" style={{ color: '#5bced4' }}>
              {currentPeriodValue}
              {format === 'percentage' && '%'}
            </Text>
            <Text className="stat-date">
              {!showSingleDate ? `${currentPeriodStartDate} - ${currentPeriodActiveDate}` : currentPeriodActiveDate}
            </Text>
          </Col>
        </Row>

        <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
          <AreaChart
            style={{ marginTop: 10 }}
            margin={{
              top: 10,
              right: 0,
              left: -60,
              bottom: -30,
            }}
            data={this.state.chartData}
            onMouseLeave={this.resetData}
          >
            <XAxis dataKey="day" tick={false} axisLine={true} />
            <YAxis tick={false} axisLine={false} />
            <RechartsTooltip content={this.customTooltip} cursor={false} />
            <Area
              type="monotone"
              dataKey="previousPeriodValue"
              stackId="1"
              stroke="1"
              fill="#b4d5d7"
            />
            <Area
              type="monotone"
              dataKey="currentPeriodValue"
              stackId="0"
              stroke="1"
              fill="#5bced4"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default AnalyticsCard;
