import React, { useState, useEffect } from 'react';
import {
  BellIcon,
  HeartIcon,
  UserGroupIcon,
  MegaphoneIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  ArrowLeftOnRectangleIcon,
  ChatBubbleBottomCenterTextIcon,
  BanknotesIcon,
  CalendarIcon,
  CloudIcon,
  ArrowUturnLeftIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  ChatBubbleLeftRightIcon,
  TrophyIcon,
  UserCircleIcon,
  AdjustmentsHorizontalIcon,
  ListBulletIcon,
  UsersIcon,
  ChartBarIcon,
  LockClosedIcon,
  SquaresPlusIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';
import * as Helper from '../Helpers/Helpers';
import _ from 'underscore';
import posthog from 'posthog-js';

const Navigation = (props) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const savedOpenKeys = JSON.parse(localStorage.getItem('openKeys')) || [];
    setOpenKeys(savedOpenKeys);
  }, []);

  useEffect(() => {
    Canny('initChangelog', {
      appID: '6578227490472526a3e3c5ef',
      position: 'bottom',
      align: 'left',
      theme: 'light', // options: light [default], dark, auto
    });
  }, []); // Empty dependency array ensures this runs once on mount

  const onOpenChange = (key) => {
    const updatedOpenKeys = openKeys.includes(key)
      ? openKeys.filter(k => k !== key)
      : [...openKeys, key];
    setOpenKeys(updatedOpenKeys);
    localStorage.setItem('openKeys', JSON.stringify(updatedOpenKeys));
  };

  const billing_url = Helper.accountSettingsUrl(props.commonData) + 'billing';

  const selectedMenuItem = () => {
    const arr = ['respond', 'leads', 'explorer', 'users'];
    const a = arr.find((el) => window.location.pathname.includes(el));
    if (a) {
      return a;
    }

    if (window.location.pathname.includes('accounts')) {
      return 'admin';
    } else if (window.location.pathname.includes('hubspot') || window.location.pathname.includes('salesforce') || window.location.pathname.includes('integrations')) {
      return 'crm';
    } else if (window.location.pathname.includes('dashboard')) {
      return 'analytics';
    } else if (window.location.pathname.includes('influencers')) {
      return 'influencers';
    } else if (window.location.pathname.includes('community')) {
      return 'community';
    } else if (window.location.pathname.includes('billing')) {
      return 'billing';
    } else if (window.location.pathname.includes('leads')) {
      return 'top_leads';
    } else if (window.location.pathname.includes('settings')) {
      return 'settings';
    } else if (window.location.pathname.includes('queue')) {
      return "queue"
    } else if (window.location.pathname.includes('my_queue')) {
      return "my_queue"
    } else {
      return 'my_lists';
    }
  };

  const logout = () => {
    posthog.reset();
    window.location.href = '/users/sign_out';
  };

  const isSelected = (key) => window.location.pathname.includes(key);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <aside
      style={{ borderWidth: '0.5px' }}
      className={`border-solid border-nav-border bg-card navigation px-2 my-1 text-sm rounded-r-2xl overflow-x-hidden overflow-y-hidden flex flex-col h-screen z-20 transition-all duration-300 ${isHovered ? 'w-64' : 'w-16'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="h-24 flex justify-between items-center p-4">
        <img className={`logo transition-all duration-300 ${isHovered ? 'w-16 h-16' : 'w-8 h-8'}`} />
      </div>

      <nav className="flex-grow font-medium">
        <ul className="list-none p-0 m-0">

					<li
						className={`cursor-pointer px-4 py-2 m-0 ${isSelected('dashboard') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-md`}
						onClick={() => (window.location.href = Helper.accountSettingsUrl(props.commonData) + 'dashboard')}
					>
						<a href={Helper.accountSettingsUrl(props.commonData) + 'dashboard'} className="flex items-center text-tertiary">
							<ChartBarIcon className="w-5 h-5 flex-shrink-0" />
							<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Performance</span>
						</a>
					</li>

					<li 
						className={`px-4 py-2 m-0 cursor-pointer ${isSelected('queue') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-md`} 
						onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'queue'}
					>
						<div className="flex items-center text-tertiary">
							<CalendarIcon className="w-5 h-5 flex-shrink-0" />
							<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
								Posts & Scheduling
							</span>
						</div>
					</li>

					<li 
						className="cursor-pointer"
						onClick={() => onOpenChange('engagement_feeds')}
					>
						<div
							className="flex px-4 py-2 items-center text-tertiary hover:bg-gray-100 rounded-md"
						>
							<ChatBubbleBottomCenterTextIcon className="w-5 h-5 flex-shrink-0" />
							<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
								Engagement feeds
							</span>
							{isHovered && (
								<ChevronDownIcon className={`ml-auto w-5 h-5 transform ${openKeys.includes('engagement_feeds') ? 'rotate-180' : ''}`} />
							)}
						</div>
					</li>

          {openKeys.includes('engagement_feeds') && (

						<ul className="list-none p-0 ml-6 border-0 border-l border-gray-100 border-solid">
							<li 
								className={`cursor-pointer pl-2 py-2 m-0 ${isSelected('my_lists') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-r-md`}
								onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'my_lists'}
							>
								<div className="flex items-center text-tertiary">
									<DocumentTextIcon className="w-5 h-5 flex-shrink-0" />
									<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Custom lists</span>
									{!_.contains(props.commonData.availableFeatures, 'my_lists') && (
										<LockClosedIcon className="w-5 h-5 ml-3" />
									)}
								</div>
							</li>
							<li 
								className={`cursor-pointer pl-2 py-2 m-0 ${isSelected('community') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-r-md`}
								onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'community'}
							>
								<div className="flex items-center text-tertiary">
									<UserGroupIcon className="w-5 h-5 flex-shrink-0" />
									<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Community</span>
								</div>
							</li>
							<li 
								className={`cursor-pointer pl-2 py-2 m-0 ${isSelected('influencers') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-r-md`}
								onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'influencers'}
							>
								<div className="flex items-center text-tertiary">
									<MegaphoneIcon className="w-5 h-5 flex-shrink-0" />
									<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Influencers</span>
									{!_.contains(props.commonData.availableFeatures, 'influencers') && (
										<LockClosedIcon className="w-5 h-5 ml-3" />
									)}
								</div>
							</li>
							<li 
								className={`cursor-pointer pl-2 py-2 m-0 ${isSelected('respond') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-r-md`}
								onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'respond'}
							>
								<div className="flex items-center text-tertiary">
									<ArrowUturnLeftIcon className="w-5 h-5 flex-shrink-0" />
									<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Replies</span>
									{!_.contains(props.commonData.availableFeatures, 'respond') && (
										<LockClosedIcon className="w-5 h-5 ml-3" />
									)}
								</div>
							</li>
            {/*
              <li className={`pl-2 py-2 m-0 ${isSelected('list_builder') ? 'bg-[#ecf8f7]' : ''} hover:bg-[#ded8c4] rounded-r-md`}>
                <a href={Helper.profileSettingsUrl(props.commonData) + 'list_builder'} className="flex items-center text-tertiary">
                  <SquaresPlusIcon className="w-5 h-5 flex-shrink-0" />
                  <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>List Builder</span>
                </a>
              </li>
            */}
            </ul>
          )}

					<li 
						className="cursor-pointer flex px-4 py-2 items-center text-tertiary hover:bg-gray-100 rounded-md"
						onClick={() => window.location.href = Helper.profileSettingsUrl(props.commonData) + 'leads'}
					>
						<CurrencyDollarIcon className="w-5 h-5 flex-shrink-0" />
						<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Top leads</span>
						{!_.contains(props.commonData.availableFeatures, 'top_leads') && (
							<LockClosedIcon className='!w-5 !h-5 ml-3 align-middle'/>
						)}
					</li>

					<li 
						className="cursor-pointer flex px-4 py-2 items-center text-tertiary hover:bg-gray-100 rounded-md"
						onClick={() => window.location.href = Helper.accountSettingsUrl(props.commonData) + 'settings'}
					>
						<UserCircleIcon className="w-5 h-5 flex-shrink-0" />
						<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Account</span>
					</li>

{/*
          {openKeys.includes('account') && (
            <ul className="list-none p-0 ml-6 border-0 border-l border-solid border-gray-100">
              <li className={`pl-2 py-2 m-0 ${isSelected('settings') ? 'bg-[#ecf8f7]' : ''} hover:bg-[#ded8c4] rounded-r-md`}>
                <a href={Helper.accountSettingsUrl(props.commonData) + 'settings'} className="flex items-center text-tertiary">
                  <AdjustmentsHorizontalIcon className="w-5 h-5 flex-shrink-0" />
                  <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Preferences</span>
                </a>
              </li>
              {props.commonData.isAccountOwner && (
                <li className={`pl-2 py-2 m-0 ${isSelected('billing') ? 'bg-[#ecf8f7]' : ''} hover:bg-[#ded8c4] rounded-r-md`}>
                  <a href={billing_url} className="flex items-center text-tertiary">
                    <CreditCardIcon className="w-5 h-5 flex-shrink-0" />
                    <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Billing</span>
                  </a>
                </li>
              )}
              {props.commonData.isAccountOwner && (
                <li className={`pl-2 py-2 m-0 ${isSelected('users') ? 'bg-[#ecf8f7]' : ''} hover:bg-[#ded8c4] rounded-r-md`}>
                  <a href={Helper.accountSettingsUrl(props.commonData) + 'users'} className="flex items-center text-tertiary">
                    <UsersIcon className="w-5 h-5 flex-shrink-0" />
                    <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Users</span>
                  </a>
                </li>
              )}
            </ul>
          )}
*/}

					<li data-canny-changelog
						className={`cursor-pointer flex px-4 py-2 m-0 hover:bg-gray-100 rounded-md`}
					>
						<BellIcon className="w-5 h-5 flex-shrink-0" />
						<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>What's new</span>
					</li>

					<li 
						className={`cursor-pointer flex px-4 py-2 m-0 ${isSelected('integrations') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-md`}
						onClick={() => window.location.href = Helper.accountSettingsUrl(props.commonData) + 'integrations'}
					>
						<CloudIcon className="w-5 h-5 flex-shrink-0" />
						<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>CRM</span>
						{!_.contains(props.commonData.availableFeatures, 'crm') && (
							<LockClosedIcon className="w-5 h-5 ml-3" />
						)}
					</li>

  {/*
          <li className={`px-4 py-2 m-0 ${isSelected('affiliate') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-md`}>
            <a href="https://aware.firstpromoter.com/" target="_blank" className="flex items-center text-tertiary">
              <CurrencyDollarIcon className="w-5 h-5 flex-shrink-0" />
              <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
                Spread the <HeartIcon className="inline-block w-5 h-5 align-middle text-red-500" /> for <BanknotesIcon className="inline-block w-5 h-5 text-green-500 align-middle" />
              </span>
            </a>
          </li>
          */}
          {props.commonData.isMasterAdmin && (

						<li 
							className={`cursor-pointer flex px-4 py-2 m-0 ${isSelected('admin') ? 'bg-[#ecf8f7]' : ''} hover:bg-gray-100 rounded-md`}
							onClick={() => window.location.href = "/admin/accounts"}
						>
							<ShieldCheckIcon className="w-5 h-5 flex-shrink-0" />
							<span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>Admin Panel</span>
						</li>

          )}
        </ul>
      </nav>

      <div className="h-12 px-4 pb-4 flex justify-center items-center font-medium">
        {isHovered && (
          <div className="flex items-center w-full">
            <img
              style={{ border: '0.5px solid #d6d5cf' }}
              className="align-middle cursor-pointer h-10 w-10 rounded-full"
              src={props.commonData.currentUserAvatar}
              alt=""
            />
            <div style={{ color:'#3d3929' }} className="align-middle flex-grow pl-3">{props.commonData.currentUserName}</div>
          </div>
        )}
        <a href="#" onClick={logout} alt="Sign out">
          <ArrowLeftOnRectangleIcon width={28} style={{ color:'#3d3929' }} />
        </a>
      </div>
    </aside>
  );
};

export default Navigation;

